import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import watching from "./images/tv_hd.png";
import mobile from "./images/telefonia_mobilna.png";
import blond from "./images/baner-blond.png";
import christmas from "./images/Christmas - 3.png";

class Gallery extends React.Component {
  render() {
    return (
      <Carousel
        autoPlay
        interval="5000"
        transitionTime="1000"
        infiniteLoop
        axis="horizontal"
        useKeyboardArrows
        showIndicators={false}
        showStatus={false}
      >
         <div>
          <img src={christmas} alt="" />
        </div>
        <div>
          <img src={blond} alt="" />
        </div>
        <div>
          <img src={watching} alt="" />
        </div>
        <div>
          <img src={mobile} alt="" />
        </div>
      </Carousel>
    );
  }
}
export default Gallery;
